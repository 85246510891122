import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/basic.css'
import './vant/index'
import { PullRefresh } from 'vant';
import VueWechatTitle from 'vue-wechat-title';  
import * as uni from './common/uni.webview.1.5.4'  

document.addEventListener("UniAppJSBridgeReady", function() {  
  console.log('进入webview', uni)
    Vue.prototype.myUni = uni  
});
Vue.use(VueWechatTitle)
Vue.use(PullRefresh);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 方法2：添加自定义属性
document.body.addEventListener('click',function( e ){
  let event = e || window.event;
  let target = event.target || event.srcElement;
  let clickMusic = target.getAttribute('clickMusic')
  if(clickMusic==='true') Vue.prototype.playAudio()
  else return false;
})
// 七牛图片前缀域名
Vue.prototype.Qimg = 'https://cdn3.relaverse.cn/appfile/'
// https://cdn3.relaverse.cn/appfile/b-c-banner.png

// 定义时间过滤器，
Vue.filter('dateFormat', function (originDate, pattern = '') {
  // 根据给定的时间字符串，得到特定的时间
  var dt = new Date(originDate)
  //   yyyy-mm-dd
  var y = dt.getFullYear()
  var m = (dt.getMonth() + 1).toString().padStart(2, '0')
  var d = dt.getDate().toString().padStart(2, '0')
  if (pattern.toLowerCase() === 'yyyy-mm-dd') {
    return `${y}-${m}-${d}`
  } else {
    var hh = dt.getHours().toString().padStart(2, '0')
    var mm = dt.getMinutes().toString().padStart(2, '0')
    var ss = dt.getSeconds().toString().padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  }
})

