import Vue from 'vue'
import {
  Icon,
  Progress,
  Overlay,
  SwipeItem,
  Swipe,
  Field,
  Form,
  Radio,
  RadioGroup,
  Button,
  Uploader,
  Image,
  Row,
  Col,
  Toast,
  NoticeBar,
  Loading,
  Divider,
  Popup,
  Stepper,
  Dialog,
  Picker,
  PasswordInput,
  NumberKeyboard,
  List,
  Cell,
  Sku,
  ShareSheet
} from 'vant'
Vue.use(Cell);
Vue.use(List);
Vue.use(Icon)
Vue.use(Progress)
Vue.use(Overlay)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Field)
Vue.use(Form)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Button)
Vue.use(Uploader)
Vue.use(Image)
Vue.use(Row)
Vue.use(Col)
Vue.use(Toast)
Vue.use(NoticeBar)
Vue.use(Loading)
Vue.use(Divider)
Vue.use(Popup)
Vue.use(Stepper)
Vue.use(Dialog)
Vue.use(Picker)
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Sku);
Vue.use(ShareSheet);
