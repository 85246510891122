import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
const Energy = () => import( /* webpackChunkName: "energy" */ '../views/Energy.vue')
const Share = () => import( /* webpackChunkName: "share" */ '../views/Share.vue')
const SeedShare = () => import( /* webpackChunkName: "seedShare" */ '../views/seedShare.vue')
const BenCaoShare = () => import( /* webpackChunkName: "benCaoShare" */ '../views/benCaoShare.vue')
const SubscriedShare = () => import( /* webpackChunkName: "share" */ '../views/subscriedShare.vue')
const Vigor = () => import( /* webpackChunkName: "vigor" */ '../views/Vigor.vue')
const User = () => import( /* webpackChunkName: "user" */ '../views/User.vue')
const IDCard = () => import( /* webpackChunkName: "IDcard" */ '../views/IDCard.vue')
const Reserve = () => import( /* webpackChunkName: "reserve" */ '../views/Reserve.vue')
const Rules = () => import( /* webpackChunkName: "rules" */ '../views/Rules.vue')
const Integral = () => import( /* webpackChunkName: "integral" */ '../views/Integral.vue')
const Member = () => import( /* webpackChunkName: "member" */ '../views/Member.vue')
const Error = () => import( /* webpackChunkName: "Error" */ '../views/Error.vue')
const Protocol = () => import( /* webpackChunkName: "Protocol" */ '../views/Protocol.vue')
const GoodsProtocol = () => import( /* webpackChunkName: "Protocol" */ '../views/goodsProtocol.vue')
const Privacy = () => import( /* webpackChunkName: "Privacy" */ '../views/Privacy.vue')
const XDetail = () => import( /* webpackChunkName: "XZDetail" */ '../views/XZDetail.vue')
const Attest = () => import( /* webpackChunkName: "attest" */ '../views/Attest.vue')
const ReserveStatus = () => import( /* webpackChunkName: "ReserveStatus" */ '../views/ReserveStatus.vue')
const MedalDetail = () => import( /* webpackChunkName: "MedalDetail" */ '../views/MedalDetail.vue')
const Gram = () => import( /* webpackChunkName: "Gram" */ '../views/Gram.vue')
const Seed = () => import( /* webpackChunkName: "Seed" */ '../views/Seed.vue')
const ChangeTal = () => import( /* webpackChunkName: "ChangeTel" */ '../views/ChangeTel.vue')
const BenCai = () => import( /* webpackChunkName: "BenCai" */ '../views/BCGM.vue')
const BRules = () => import( /* webpackChunkName: "BRules" */ '../views/BRules.vue')
const cxbcSynthesis = () => import( /* webpackChunkName: "BRules" */ '../views/CXBC/cxbcSynthesis.vue')
const cxbc = () => import( /* webpackChunkName: "BRules" */ '../views/CXBC/cxbc.vue')
const cxbcRule = () => import( /* webpackChunkName: "BRules" */ '../views/CXBC/cxbcRule.vue')
const cxbcShare = () => import( /* webpackChunkName: "BRules" */ '../views/CXBC/cxbcShare.vue')
const BcSynthesis = () => import( /* webpackChunkName: "BRules" */ '../views/BcSynthesis/BcSynthesis.vue')
const HCmyCP = () => import( /* webpackChunkName: "BRules" */ '../views/BcSynthesis/HCmyCp.vue')


// 艾草胸针第三期拉新活动
const thirty = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/Thirty.vue')
const ThirtyRank = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/ThirtyRank.vue')
const ThirtyShare = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/ThirtyShare.vue')
const ThirtyActShare = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/ThirtyActShare.vue')
const Thirtyrule = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/Thirtyrule.vue')
const inviteRank = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/inviteRank.vue')
const LszShare = () => import( /* webpackChunkName: "BRules" */ '../views/CXBC/LszShare.vue')
const ThirtyFuse = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/ThirtyFuse.vue')
const FuseStuff = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/FuseStuff.vue')
const CompoRecord = () => import( /* webpackChunkName: "BRules" */ '../views/thirty/CompoRecord.vue')
const Activity = () => import( /* webpackChunkName: "BRules" */ '../views/Activity.vue')
const ActivityRule = () => import( /* webpackChunkName: "BRules" */ '../views/ActivityRule.vue')

// 李时珍头像征集活动 一版
const lszSolicitationIndex = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/index.vue')
const lszSolicitationHdgz = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/hdgz.vue')
const lszSolicitationHelpPage = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/helpPage.vue')
const lszSolicitationCollection = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/collection.vue')
const lszSolicitationCpxq = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/cpxq.vue')
const solicitationRankingList = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/rankingList.vue')
const invitationRecord = () => import( /* webpackChunkName: "BRules" */ '../views/lszSolicitation/invitationRecord.vue')

// 李时珍头像征集活动 二版
const lszSeIndex = () => import( /* webpackChunkName: "BRules" */ '../views/lszSe/index.vue')
const lszSeCollection = () => import( /* webpackChunkName: "BRules" */ '../views/lszSe/collection.vue')
const lszSeCpxq = () => import( /* webpackChunkName: "BRules" */ '../views/lszSe/cpxq.vue')
const lszSeRankingList = () => import( /* webpackChunkName: "BRules" */ '../views/lszSe/rankingList.vue')

// 李时珍头像征集活动 三版
const SolicitationIndex = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/index.vue')
const SolicitationHdgz = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/hdgz.vue')
const SolicitationHelpPage = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/helpPage.vue')
const SolicitationCollection = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/collection.vue')
const SolicitationCpxq = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/cpxq.vue')
const solicitationRankingLista = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/rankingList.vue')
const invitationRecorda = () => import( /* webpackChunkName: "BRules" */ '../views/Solicitation/invitationRecord.vue')

//睡眠活动拉新
const sleepActivityIndex = () => import( /* webpackChunkName: "BRules" */ '../views/sleepActivity/sleepActivityIndex.vue')
const sleepActivityRankingList = () => import( /* webpackChunkName: "BRules" */ '../views/sleepActivity/rankingList.vue')
const sleepActivityHdgz = () => import( /* webpackChunkName: "BRules" */ '../views/sleepActivity/hdgz.vue')
const sleepActivityrecord = () => import( /* webpackChunkName: "BRules" */ '../views/sleepActivity/record.vue')

//元气商城
const yqMallIndex = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/index.vue')
const yqMallShopping = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/shopping.vue')
const yqMallOrder = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/order.vue')
const yqMallTabs = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/yqTabs.vue')
const yqMallSearch = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/search.vue')
const yqMallDetail = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/details.vue')
const yqMallAddressList = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/addressList.vue')
const yqMallConfirmOrder = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/confirmOrder.vue')
const yqMallPayOrder = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/payOrder.vue')
const yqMallKf = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/kefu.vue')
const yqMallSet = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/set.vue')
const yqMallPayProtocol = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/payProtocol.vue')
const yqMallPawnRules = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/pawnRules.vue')
const yqMallPreviewPage = () => import( /* webpackChunkName: "BRules" */ '../views/yqMall/previewPage.vue')

//服贸会活动
const FuMaoHui = () => import(/* webpackChunkName: "fumaohui" */ '../views/FuMaoHui/FuMaoHui.vue')

//元气当铺拉新活动
const yqPreheatIndex = () => import(/* webpackChunkName: "fumaohui" */ '../views/yqPreheat/index.vue')
const yqPreheatRanking = () => import(/* webpackChunkName: "fumaohui" */ '../views/yqPreheat/rankingList.vue')

Vue.use(VueRouter)

const routes = [{
    path: '/reserve',
    name: 'reserve',
    meta: {
      keepAlive: false
    },
    component: Reserve
  },
  {
    path: '/reserveStatus',
    name: 'reserveStatus',
    meta: {
      keepAlive: false
    },
    component: ReserveStatus
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      keepAlive: false
    },
    component: Home
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      keepAlive: false
    },
    component: Activity
  },
  {
    path: '/energy',
    name: 'energy',
    meta: {
      keepAlive: false
    },
    component: Energy
  },
  {
    path: '/vigor',
    name: 'vigor',
    meta: {
      keepAlive: true
    },
    component: Vigor
  },
  {
    path: '/seed',
    name: 'seed',
    meta: {
      keepAlive: true
    },
    component: Seed
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      keepAlive: false
    },
    component: Share
  },
  {
    path: '/seedShare',
    name: 'seedShare',
    meta: {
      keepAlive: false
    },
    component: SeedShare
  },
  {
    path: '/subscriedShare',
    name: 'subscriedShare',
    component: SubscriedShare
  },
  {
    path: '/benCaoShare',
    name: 'benCaoShare',
    component: BenCaoShare
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      keepAlive: false
    },
    component: User
  },
  {
    path: '/idCard',
    name: 'idCard',
    meta: {
      keepAlive: true
    },
    component: IDCard
  },
  {
    path: '/reserve',
    name: 'reserve',
    meta: {
      keepAlive: true
    },
    component: Reserve
  },
  {
    path: '/rules',
    name: 'rules',
    meta: {
      keepAlive: true
    },
    component: Rules
  },
  {
    path: '/integral',
    name: 'integral',
    meta: {
      keepAlive: true
    },
    component: Integral
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      keepAlive: true
    },
    component: Member
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      keepAlive: true
    },
    component: Error
  },
  {
    path: '/protocol',
    name: 'protocol',
    meta: {
      keepAlive: true
    },
    component: Protocol
  },
  {
    path: '/goodsProtocol',
    name: 'goodsProtocol',
    meta: {
      keepAlive: true
    },
    component: GoodsProtocol
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      keepAlive: true
    },
    component: Privacy
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      keepAlive: false
    },
    component: XDetail
  },
  {
    path: '/medalDetail',
    name: 'medalDetail',
    meta: {
      keepAlive: false
    },
    component: MedalDetail
  },
  {
    path: '/attest',
    name: 'attest',
    meta: {
      keepAlive: false
    },
    component: Attest
  },
  {
    path: '/gram',
    name: 'gram',
    meta: {
      keepAlive: false
    },
    component: Gram
  },
  {
    path: '/changeTel',
    name: 'changeTel',
    meta: {
      keepAlive: true
    },
    component: ChangeTal
  },
  {
    path: '/',
    name: 'benCai',
    meta: {
      keepAlive: false
    },
    component: BenCai
  },
  {
    path: '/bRules',
    name: 'bRules',
    meta: {
      keepAlive: false
    },
    component: BRules
  },
  // 冲寻百草合成页面
  {
    path: '/cxbcSynthesis',
    name: 'cxbcSynthesis',
    meta: {
      keepAlive: false
    },
    component: cxbcSynthesis
  },
  // 冲寻百草首页
  {
    path: '/cxbc',
    name: 'cxbc',
    meta: {
      keepAlive: false
    },
    component: cxbc
  },
  // 重寻百草规则
  {
    path: '/cxbcRule',
    name: 'cxbcRule',
    meta: {
      keepAlive: false
    },
    component: cxbcRule
  },
  // 重寻百草规则
  {
    path: '/cxbcShare',
    name: 'cxbcShare',
    meta: {
      keepAlive: false
    },
    component: cxbcShare
  },
  // 合成
  {
    path: '/BcSynthesis',
    name: 'BcSynthesis',
    meta: {
      keepAlive: false
    },
    component: BcSynthesis
  }, {
    path: '/HCmyCP',
    name: 'HCmyCP',
    meta: {
      keepAlive: false
    },
    component: HCmyCP
  },
  {
    path: '/thirty',
    name: 'thirty',
    meta: {
      keepAlive: false
    },
    component: thirty
  }, {
    path: '/ThirtyRank',
    name: 'ThirtyRank',
    meta: {
      keepAlive: false
    },
    component: ThirtyRank
  },
  {
    path: '/ThirtyShare',
    name: 'ThirtyShare',
    meta: {
      keepAlive: false
    },
    component: ThirtyShare
  }, {
    path: '/ThirtyActShare',
    name: 'ThirtyActShare',
    meta: {
      keepAlive: false
    },
    component: ThirtyActShare
  }, {
    path: '/Thirtyrule',
    name: 'Thirtyrule',
    meta: {
      keepAlive: false
    },
    component: Thirtyrule
  }, {
    path: '/ActivityRule',
    name: 'ActivityRule',
    meta: {
      keepAlive: false
    },
    component: ActivityRule
  }, {
    path: '/LszShare',
    name: 'LszShare',
    meta: {
      keepAlive: false
    },
    component: LszShare
  }, {
    path: '/inviteRank',
    name: 'inviteRank',
    meta: {
      keepAlive: false
    },
    component: inviteRank
  }, {
    path: '/ThirtyFuse',
    name: 'ThirtyFuse',
    meta: {
      keepAlive: false
    },
    component: ThirtyFuse
  }, {
    path: '/FuseStuff',
    name: 'FuseStuff',
    meta: {
      keepAlive: false
    },
    component: FuseStuff
  }, {
    path: '/CompoRecord',
    name: 'CompoRecord',
    meta: {
      keepAlive: false
    },
    component: CompoRecord
  }, {
    path: '/lszSolicitationIndex',
    name: 'lszSolicitationIndex',
    meta: {
      keepAlive: false
    },
    component: lszSolicitationIndex
  }, {
    path: '/lszSolicitationHdgz',
    name: 'lszSolicitationHdgz',
    meta: {
      keepAlive: false
    },
    component: lszSolicitationHdgz
  }, {
    path: '/lszSolicitationHelpPage',
    name: 'lszSolicitationHelpPage',
    meta: {
      keepAlive: false
    },
    component: lszSolicitationHelpPage
  }, {
    path: '/lszSolicitationCollection',
    name: 'lszSolicitationCollection',
    meta: {
      keepAlive: false
    },
    component: lszSolicitationCollection
  }, {
    path: '/lszSolicitationCpxq',
    name: 'lszSolicitationCpxq',
    meta: {
      keepAlive: false
    },
    component: lszSolicitationCpxq
  }, {
    path: '/solicitationRankingList',
    name: 'solicitationRankingList',
    meta: {
      keepAlive: false
    },
    component: solicitationRankingList
  }, {
    path: '/invitationRecord',
    name: 'invitationRecord',
    meta: {
      keepAlive: false
    },
    component: invitationRecord
  }, {
    path: '/SolicitationIndex',
    name: 'SolicitationIndex',
    meta: {
      keepAlive: false
    },
    component: SolicitationIndex
  }, {
    path: '/SolicitationHdgz',
    name: 'SolicitationHdgz',
    meta: {
      keepAlive: false
    },
    component: SolicitationHdgz
  }, {
    path: '/SolicitationHelpPage',
    name: 'SolicitationHelpPage',
    meta: {
      keepAlive: false
    },
    component: SolicitationHelpPage
  }, {
    path: '/SolicitationCollection',
    name: 'SolicitationCollection',
    meta: {
      keepAlive: false
    },
    component: SolicitationCollection
  }, {
    path: '/SolicitationCpxq',
    name: 'SolicitationCpxq',
    meta: {
      keepAlive: false
    },
    component: SolicitationCpxq
  }, {
    path: '/solicitationRankingLista',
    name: 'solicitationRankingLista',
    meta: {
      keepAlive: false
    },
    component: solicitationRankingLista
  }, {
    path: '/invitationRecorda',
    name: 'invitationRecorda',
    meta: {
      keepAlive: false
    },
    component: invitationRecorda
  }, {
    path: '/fumaohui',
    name: 'fumaohui',
    meta: {
      keepAlive: false
    },
    component: FuMaoHui
  }
  , {
    path: '/sleepActivityIndex',
    name: 'sleepActivityIndex',
    meta: {
      keepAlive: false,
      title: '118早睡行动预约开启'
    },
    component: sleepActivityIndex
  }
  , {
    path: '/sleepActivityRankingList',
    name: 'sleepActivityRankingList',
    meta: {
      keepAlive: false,
      title: '排行榜'
    },
    component: sleepActivityRankingList
  }
  , {
    path: '/sleepActivityHdgz',
    name: 'sleepActivityHdgz',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: sleepActivityHdgz
  }, {
    path: '/sleepActivityrecord',
    name: 'sleepActivityrecord',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: sleepActivityrecord
  }, {
    path: '/yqMallIndex',
    name: 'yqMallIndex',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallIndex
  }, {
    path: '/yqMallShopping',
    name: 'yqMallShopping',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallShopping
  }, {
    path: '/yqMallOrder',
    name: 'yqMallOrder',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallOrder
  }, {
    path: '/yqMallTabs',
    name: 'yqMallTabs',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallTabs
  }, {
    path: '/yqMallSearch',
    name: 'yqMallSearch',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallSearch
  }, {
    path: '/yqMallDetail',
    name: 'yqMallDetail',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallDetail
  }, {
    path: '/yqMallConfirmOrder',
    name: 'yqMallConfirmOrder',
    meta: {
      keepAlive: true,
      title: '轻松小镇'
    },
    component: yqMallConfirmOrder
  }, {
    path: '/yqMallAddressList',
    name: 'yqMallAddressList',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallAddressList
  }, {
    path: '/yqMallPayOrder',
    name: 'yqMallPayOrder',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallPayOrder
  }, {
    path: '/yqMallKf',
    name: 'yqMallKf',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallKf
  }, {
    path: '/yqMallSet',
    name: 'yqMallSet',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallSet
  }, {
    path: '/yqMallPayProtocol',
    name: 'yqMallPayProtocol',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallPayProtocol
  }, {
    path: '/yqMallPawnRules',
    name: 'yqMallPawnRules',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallPawnRules
  }, {
    path: '/yqMallPreviewPage',
    name: 'yqMallPreviewPage',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqMallPreviewPage
  }
  , {
    path: '/lszSeIndex',
    name: 'lszSeIndex',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: lszSeIndex
  }
  , {
    path: '/lszSeCollection',
    name: 'lszSeCollection',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: lszSeCollection
  }
  , {
    path: '/lszSeCpxq',
    name: 'lszSeCpxq',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: lszSeCpxq
  }
  , {
    path: '/lszSeRankingList',
    name: 'lszSeRankingList',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: lszSeRankingList
  }, {
    path: '/yqPreheatIndex',
    name: 'yqPreheatIndex',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqPreheatIndex
  }, {
    path: '/yqPreheatRanking',
    name: 'yqPreheatRanking',
    meta: {
      keepAlive: false,
      title: '轻松小镇'
    },
    component: yqPreheatRanking
  }
]


const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  if (!(navigator.userAgent.match(
      /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    console.log('pc端')
    if (to.path === '/error') {
      next()
    } else {
      next('/error')
    }
  } else {
    const invited_id = localStorage.getItem('invited_id')
    const user_id = localStorage.getItem('user_id')
    const activity_id = localStorage.getItem('activity_id')
    const search = window.location.search
    if (search && search.indexOf('?code=') > -1) {
      // 微信授权的重定向
      if (search) {
        const arrayParams = search.replace('?', '').split('&')
        let hash = ''
        let code = ''
        arrayParams.forEach(item => {
          const temp = item.split('=')
          if (temp[0] == 'code') {
            code = temp[1]
            localStorage.setItem('weiCode', temp[1])
          }
          else if (temp[0] == 'state') {
            hash = temp[1]
          }
        })
        window.location.href = `${window.location.origin}/#/${hash}?code=${code}`
      }
    }
    else if (localStorage.getItem('token') && invited_id && activity_id && !window.location.search) {
      const origin = window.location.origin
      const hash = window.location.hash
      window.location.href = `${origin}/?invited_id=${user_id}&activity_id=${activity_id}${hash}`
    } else {
      next()
    }
  }
})

export default router
