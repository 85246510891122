import {
  wxConfig
} from '../src/api/user'
import wx from 'weixin-js-sdk'

export const getWxConfig = async (title, desc) => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') !== -1
  if (!isWeixin) {
    return
  }
  let number = ''
  try {
    const res = await wxConfig({
      url: window.location.href,
      js: ['updateAppMessageShareData', 'updateTimelineShareData']
    })
    number = localStorage.getItem('count') || parseInt(Math.random() * 100 + 1)
    wx.config({
      debug: false, // 开启调试模式
      appId: res.data.wx.appId, // 必填，公众号的唯一标识
      timestamp: res.data.wx.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.wx.nonceStr, // 必填，生成签名的随机串
      signature: res.data.wx.signature, // 必填，签名
      jsApiList: [
        'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        'updateTimelineShareData' // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
      ]
    })
    wx.error(function (res) { })
  } catch (e) {
    console.log(e)
  }
  wx.ready(function () { // 需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title: title || '「轻松小镇」首发纪念款数字藏品', // 分享标题
      desc: desc || '我是第' + number + '位预约者，请为我助力点击参与，与好友共同入驻轻松小镇数字空间！',
      // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      link: window.location.href.split('#')[0] + '#' + window.location.href.split('#')[1],
      imgUrl: window.location.origin + '/' + require('../src/assets/img/logo.png'), // 分享图标
      success: function () {
        // 设置成功
      }
    })
    wx.updateTimelineShareData({
      title: title || '「轻松小镇」首发纪念款数字藏品', // 分享标题
      // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      link: window.location.href.split('#')[0] + '#' + window.location.href.split('#')[1],
      imgUrl: window.location.origin + '/' + require('../src/assets/img/logo.png'),
      success: function () {
        // 设置成功
      }
    })
  })
}

export const getString = () => {
  if (window.location.search) {
    const search = window.location.search
    const array = search.replace('?', '').split('&')
    array.forEach(item => {
      const temp = item.split('=')
      temp[1] = temp[1].replace('/', '')
      if (temp[0] === 'invited_id' && localStorage.getItem('invited_id')) {
        if (temp[1] == localStorage.getItem('user_id')) {
          console.log(1111)
        } else {
          localStorage.setItem(temp[0], temp[1])
        }
      } else {
        localStorage.setItem(temp[0], temp[1])
      }
    })
  }
}
// h5跳转app的方法
export const toApp = (url) => {
  console.log(".>>>>>>.")
  //如果能跳直接就去需要跳转的页面了
  window.location.href = url;
  setTimeout(function () {
    // 这个定时器是如果没有跳走就去app store看他有没有下载app
    //  跳转app store
    let r = confirm("未安装APP? 是否去App store查看");
    if (r) {
      window.location = "https://appfile.relaverse.cn/m/download.html";
    }
  }, 5000);
}
