import Vue from 'vue'
import Vuex from 'vuex'
import {
  userApi
} from '../api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || {
      nickname: '',
      sex: 4,
      avatar: '0',
      code: '',
      mobile: '',
      isSign: 0,
      stone: 0,
      score: 0, // 元气
      box: 0,
      seed: 0,
      certification: 0,
      join_reserve: 0, // 是否加入当前活动 0未加入
      day: 0, // 连续签到天数
      idCard: '', // 身份证号
      name: '', // 真实姓名
      isAttested: 0, // 是否认证
      medal: 0, // 是否有勋章 1有
      id: 0,
      baZi: 0, // 八字
      hasGroup: 0 // 未加入社群
    },
    token: localStorage.getItem('token') || '',
    quality: {
      fire: {}, //火
      gold: {}, //金
      wood: {}, //木
      water: {}, //水
      Soil: {}, //土
    },
    rule: '',
    Silverfuse: [], //白银材料数组
    Goldfuse: [], //黄金材料数组
    Platinumfuse: [], //白金材料数组
    Diamondfuse: [], //砖石材料数组
    synthesieType: '',
    invited_code: '',//上级用户CODE
    invited_ids: '',//上级用户ID
    lszCollectionSelect: {}, //李时珍活动选择藏品

  },
  mutations: {
    setUser(state, data) {
      state.user.nickname = data.username
      state.user.certification = data.certification
      state.user.sex = Number(data.sex) || 4
      state.user.code = data.code
      state.user.avatar = data.avator || '0'
      state.user.mobile = data.mobile
      state.user.isSign = data.isSign || 0
      state.user.stone = data.store || 0
      state.user.score = data.score || 0
      state.user.box = data.box || 0
      state.user.join_reserve = data.join_reserve || 0
      state.user.day = data.day || 0
      state.user.idCard = data.idCard
      state.user.name = data.name
      state.user.isAttested = data.certification || 0
      state.user.medal = data.medal || 0
      state.user.seed = data.seed || 0
      state.user.idCard = data.idCard
      state.user.id = data.id
      state.user.baZi = data.bazi || 0
      state.user.hasGroup = data.join_wx_qun || 0
      localStorage.setItem('user_id', state.user.id)
      localStorage.setItem('code', state.user.code)
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    setInvitedCode(state, data){
      state.invited_code = data
      localStorage.setItem('invited_code', state.invited_code)
    },
    setInvitedIds(state, data){
      state.invited_ids = data
      localStorage.setItem('invited_ids', state.invited_ids)
    },
    setToken(state, data) {
      state.token = data
      localStorage.setItem('token', state.token)
    },
    setSignIn(state, data) {
      state.user.isSign = data
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    setLszCollectionSelect(state, data) {
      state.lszCollectionSelect = data
    },
    setfire(state, data) {
      state.quality.fire = data
    },
    setgold(state, data) {
      state.quality.gold = data
    },
    setwood(state, data) {
      state.quality.wood = data
    },
    setwater(state, data) {
      state.quality.water = data
    },
    setSoil(state, data) {
      state.quality.Soil = data
    },

    setSilverfuse(state, data) {
      state.Silverfuse = data
    },
    setGoldfuse(state, data) {
      state.Goldfuse = data
    },
    setPlatinumfuse(state, data) {
      state.Platinumfuse = data
    },
    setDiamondfuse(state, data) {
      state.Diamondfuse = data
    },
    setSynthesieType(state, data) {
      state.synthesieType = data
    }
  },
  actions: {
    async getUser({
      commit
    }) {
      const res = await userApi({})
      commit('setUser', {
        isSign: res.data.signs,
        ...res.data.user
      })
    }
  },
  modules: {}
})
