const baseUrl = {

  domain: process.env.NODE_ENV === 'development' ? 'http://api.relaverse.cn/' : 'http://api.relaverse.cn/',
  domain2: process.env.NODE_ENV === 'development' ? 'http://kill.relaverse.cn/' : 'http://kill.relaverse.cn/',
  // domain: process.env.NODE_ENV === 'development' ? 'http://8.142.162.201/' : 'http://8.142.162.201/',
  // domain2: process.env.NODE_ENV === 'development' ? 'http://kill.qs.demos.cn.vc/' : 'http://kill.qs.demos.cn.vc/',

  yqPreheatDomain: process.env.NODE_ENV === 'development' ? 'http://api.relaverse.cn/' : 'http://api.relaverse.cn/'
  // yqPreheatDomain: process.env.NODE_ENV === 'development' ? 'http://easy-town.test.canghua.cc/' : 'http://easy-town.test.canghua.cc/'

}
module.exports = baseUrl
