import http from './http.js'

// 签到之类的规则
export const configApi = (data) => {
  return http.post('/index/Member/activity', data)
}

export const wxConfig = (data) => {
  return http.post('index/login/wx', data)
}

export const loginApi = (data) => {
  return http.post('/index/login/signin', data)
}

export const codeApi = (data) => {
  return http.post('/index/code/getCode', data)
}

export const userApi = (data) => {
  return http.post('/index/member/user_info', data)
}

export const signInApi = (data) => {
  return http.post('/index/Member/sign', data)
}

export const getQiNiuApi = (data) => {
  return http.post('/index/Member/revise_user', data)
}

export const saveInfoApi = (data) => {
  return http.post('/index/Member/get_change_config', data)
}

//  预约信息
export const reserveStatus = (data) => {
  return http.post('/index/Reserve/reserve', data)
}

//  预约活动
export const reserveApi = (data) => {
  return http.post('/index/Reserve/join_reserve', data)
}

//  邀请好友
export const share = (data) => {
  return http.post('/index/Reserve/invite', data)
}

//  元石记录
export const stoneApi = (data) => {
  return http.post('/index/Points/storelog', data)
}

// 查询中签结果
export const getResultApi = (data) => {
  return http.post('/index/Reserve/view_results', data)
}

// 积分商城
export const goodsApi = (data) => {
  return http.post('/index/mall/mall', data)
}

// 兑换
export const convertApi = (data) => {
  return http.post('/index/mall/bay_mall', data)
}

// 得到验证字
export const getTextApi = (data) => {
  return http.get('/index/Code/get_see_caltext', {
    params: {
      encryption: data
    }
  })
}

// 验证字
export const checkApi = (data) => {
  return http.post('/index/Code/verify', data)
}

// 实名认证
export const attestApi = (data) => {
  return http.post('/index/Member/certification', data)
}

// 立即请购
export const nowBuyApi = (data) => {
  return http.post('/index/Seckill/snapup', data)
}

// 修改手机号
export const changeTelApi = (data) => {
  return http.post('/index/Member/get_change_mobile', data)
}

// 修改手机号
export const getNFTDetailApi = (data) => {
  return http.post('/index/Reserve/nft_massager', data)
}

// 预支付
export const getPayOrder = (data) => {
  return http.post('/score/Purchase/purchase', data)
}

// 放弃订单
export const giveUpApi = (data) => {
  return http.post('/score/Purchase/delorder', data)
}

// 订单状态
export const getOrderStatusApi = (data) => {
  return http.post('/wechat/Api/queryorder', data)
}

// 得到种子库存情况
export const getSeedStatusApi = (data) => {
  return http.post('/score/Purchase/everyday', data)
}

// 订阅
export const subscribe = (data) => {
  return http.post('/score/Purchase/subscribe', data)
}

// 得到竞猜数据
export const getGuessApi = (data) => {
  return http.post('/score/guess/getGuess', data)
}

// 提交竞猜
export const submitOption = (data) => {
  return http.post('/score/guess/guess', data)
}

// 得到竞猜结果
export const getGuessResultApi = (data) => {
  return http.post('/score/Guess/seeGuess', data)
}

// 是否可以微信支付
export const isWeiPayApi = (data) => {
  return http.post('/score/Purchase/wx_open', data)
}

// 是否已注册
export const loginCheck = (data) => {
  return http.get('/index/login/check', {
    params: data
  })
}
