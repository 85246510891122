<template>
  <div id="app">
    <!--      <keep-alive>-->
    <!--        <router-view/>-->
    <!--      </keep-alive>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title" />
    </keep-alive>
    <transition name="fade">
      <router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title" />
    </transition>
  </div>
</template>

<script>
import { getString } from "../tool";
import { userApi } from "./api/user";
import { mapMutations } from "vuex";

export default {
  created() {
    getString();
    // this.getUser()
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    async getUser() {
      if (localStorage.getItem("user_id")) {
        this.change();
        return;
      }
      try {
        const res = await userApi({});
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
        this.change();
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    change() {
      const invitedId = localStorage.getItem("invited_id");
      const activity_id = localStorage.getItem("activity_id");
      if (invitedId != localStorage.getItem("user_id")) {
        const origin = window.location.origin;
        const hash = window.location.hash;
        window.location.href = `${origin}/?invited_id=${localStorage.getItem(
          "user_id"
        )}&activity_id=${activity_id}${hash}`;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
div {
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
}
</style>
